define("discourse/plugins/discourse-private-replies/discourse/templates/connectors/category-custom-security/private-replies", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.siteSettings.private_replies_enabled}}
    <section>
      <h3>{{i18n "private_replies.title"}}</h3>
    </section>
    {{#if this.siteSettings.private_replies_on_selected_categories_only}}
      <section class="field category_private_replies_enabled">
          <label>
          <Input
              @type="checkbox"
              @checked={{this.args.outletArgs.category.custom_fields.private_replies_enabled}}
              {{on "change" this.enable_for_category}}
          />
          {{i18n "private_replies.private_replies_enabled"}}
          </label>
      </section>
    {{/if}}
    {{#if this.category_private_replies_enabled}}
      <section class="field category_private_replies_default_enabled">
          <label>
          <Input
              @type="checkbox"
              @checked={{this.args.outletArgs.category.custom_fields.private_replies_default_enabled}}
          />
          {{i18n "private_replies.category_default_enabled"}}
          </label>
          <span>{{i18n "private_replies.category_default_subtext"}}</span>
      </section>
    {{/if}}
  {{/if}}
  */
  {
    "id": "4yJRlPy3",
    "block": "[[[41,[30,0,[\"siteSettings\",\"private_replies_enabled\"]],[[[1,\"  \"],[10,\"section\"],[12],[1,\"\\n    \"],[10,\"h3\"],[12],[1,[28,[35,3],[\"private_replies.title\"],null]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[30,0,[\"siteSettings\",\"private_replies_on_selected_categories_only\"]],[[[1,\"    \"],[10,\"section\"],[14,0,\"field category_private_replies_enabled\"],[12],[1,\"\\n        \"],[10,\"label\"],[12],[1,\"\\n        \"],[8,[39,5],[[4,[38,6],[\"change\",[30,0,[\"enable_for_category\"]]],null]],[[\"@type\",\"@checked\"],[\"checkbox\",[30,0,[\"args\",\"outletArgs\",\"category\",\"custom_fields\",\"private_replies_enabled\"]]]],null],[1,\"\\n        \"],[1,[28,[35,3],[\"private_replies.private_replies_enabled\"],null]],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"category_private_replies_enabled\"]],[[[1,\"    \"],[10,\"section\"],[14,0,\"field category_private_replies_default_enabled\"],[12],[1,\"\\n        \"],[10,\"label\"],[12],[1,\"\\n        \"],[8,[39,5],null,[[\"@type\",\"@checked\"],[\"checkbox\",[30,0,[\"args\",\"outletArgs\",\"category\",\"custom_fields\",\"private_replies_default_enabled\"]]]],null],[1,\"\\n        \"],[1,[28,[35,3],[\"private_replies.category_default_enabled\"],null]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,1],[12],[1,[28,[35,3],[\"private_replies.category_default_subtext\"],null]],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[]],null]],[],false,[\"if\",\"section\",\"h3\",\"i18n\",\"label\",\"input\",\"on\",\"span\"]]",
    "moduleName": "discourse/plugins/discourse-private-replies/discourse/templates/connectors/category-custom-security/private-replies.hbs",
    "isStrictMode": false
  });
});